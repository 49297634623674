export default {
  table: {
    showing: "Affiche",
    to: "à",
    of: "de",
    results: "résultats",
    previous: "Précédent",
    next: "Suivant",
    related: "Sur le même sujet",
  },
  list: {
    results: "Affiche 1 à {displaying} de {total} résultats",
    "load-more": "Charger plus",
  },
}
